<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">方案审批</h3>
    <div class="detail">
      <el-timeline v-if="stepApproval">
        <el-timeline-item
            class="timeLine"
            v-for="(item,ind) in stepApproval"
            :key="ind">
          <template slot="dot">
              <span class="dot" :class="{'blue':item.approveResult==1}">
                <i class="el-icon-check" v-if="item.approveResult!==1"></i>
                <template v-else>{{ ind + 1 }}</template>
              </span>
          </template>
          <div class="flex stepF">
            <h3>{{ ind + 1 }}. {{ item.approverName }}</h3>
          </div>
          <p class="approverDate">{{ item.approverDate | formatTimer }}</p>
          <p class="approveResult">
            <span>{{ item.approveResult == 0 ? '待审批' : item.approveResult == 1 ? '审批中' : item.approveResult == 2 ? '通过' : '拒绝' }}</span>
            <span>{{ item.deptName }}--{{ item.roleNames }}</span>
          </p>
          <p class="approverDate">备注：{{ item.remark }}</p>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>

</template>

<script>
export default {
  name: "approval",
  props: ["id"],
  data() {
    return {
      stepApproval: []
    }
  },
  mounted() {
    this.getApproval()
  },

  methods: {
    getApproval() {
      this.$get("/subject/approval/" + this.id).then((res) => {
        if (res.status == 200) {
          this.stepApproval = res.data
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
